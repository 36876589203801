import React from "react";
import classNames from "classnames";

const OutlinedButton = ({
  text = "Submit",
  disabled = false,
  type,
  onClick,
  className,
}) => {
  return (
    <button
      type={type}
      className={classNames("obtn", className, {
        "obtn--disabled": disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      <span>{text}</span>
    </button>
  );
};

export default OutlinedButton;
